import React from 'react';
import styled, { css } from 'styled-components';
import { LangDirectionContext } from '../../context/LangDirectionContext';
import { Card } from './Card';

const CardsListStyled = styled.div`
  z-index: 5;
  background-color: #f9f9f9;
  width: 100%;
  position: relative;
  padding: 16px 0 6px 0;
  display: flex;
  margin-bottom: 0;
  margin-top: auto;

  @media (max-width: ${({ theme }) => theme.viewports.xs}) {
    width: 92%;
    margin: 0 auto 0 auto;
  }

  @media (min-width: ${({ theme }) => theme.viewports.sm}) {
    background-color: transparent;
    padding: 10px 0 20px 0;
  }
  @media (max-height: 414px) {
    padding: 0 0 0 0;
  }

  @media only screen and (min-width: ${({ theme }) => theme.viewports.sm}) and (max-device-width: ${({ theme }) =>
      theme.viewports.mdx}) and (orientation: portrait) {
    padding: 0;
  }
`;

const CardsListWrapperStyled = styled.div`
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
  align-items: stretch;
  justify-content: center;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  width: 100%;

  @media only screen and (max-width: ${({ theme }) => theme.viewports.md}) and (orientation: landscape) {
    justify-content: center;
    flex-wrap: nowrap;
  }

  @media (min-width: ${({ theme }) => theme.viewports.sm}) {
    flex-direction: row;
    justify-content: center;
    flex-wrap: nowrap;
    max-width: 1292px;
  }

  @media only screen and (min-width: ${({ theme }) => theme.viewports.sm}) and (max-device-width: ${({ theme }) =>
      theme.viewports.mdx}) and (orientation: portrait) {
    // flex-wrap: wrap;
    margin: auto;
  }
`;

const CardHoldersStyled = styled.div`
  display: flex;
  margin: 0 0 10px 0;
  width: 100%;
  color: ${(props) => props.theme.colors.medGray};
  font-weight: bold;
  font-size: 18px;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
  position: relative;

  span .icon-path:not(.path1):before {
    ${(props) =>
      props.direction === 'rtl' &&
      css`
         {
          margin-right: -1em;
          margin-left: auto;
        }
      `}
  }

  @media only screen and (max-width: ${({ theme }) => theme.viewports.sm}) {
    &.which-of-three {
      max-width: 47%;
      margin-left: auto;
      margin-right: auto;
    }
  }

  &.for-what-fits {
    max-width: 15%;
    margin-left: 5px;
    margin-right: 5px;
  }

  &.which-of-image {
    max-width: 90px;
    margin: 10px;
  }

  // @media only screen and (max-width: ${({ theme }) => theme.viewports.md}) and (orientation: landscape) {
  //   margin: 0 10px;
  // }

  @media (min-width: ${({ theme }) => theme.viewports.sm}) and (min-height: 400px) {
    // margin: 0 10px;
    flex-direction: column;
    justify-content: flex-start;
    max-width: 170px;

    &.which-of-three,
    &.which-of-image {
      min-height: 50px;
      max-width: 150px;
      @media (min-width: ${({ theme }) => theme.viewports.xl}) {
        min-height: 90px;
      }
      button {
        font-size: 30px;
        font-weight: 600;
      }
      .numbers {
        display: none;
      }
    }

    &.which-of-image {
      .numbers {
        display: block;
      }
    }

    &.for-tables {
      min-height: 90px;

      @media only screen and (min-width: ${({ theme }) => theme.viewports.sm}) and (max-device-width: ${({ theme }) =>
          theme.viewports.mdx}) and (orientation: portrait) {
        min-height: 72px;
      }
    }

    &.for-text {
      max-width: 320px;

      @media only screen and (min-width: ${({ theme }) => theme.viewports.sm}) and (max-device-width: ${({ theme }) =>
          theme.viewports.mdx}) and (orientation: portrait) {
        max-width: 215px;
      }

      button {
        font-size: 22px;
        font-weight: 600;
      }
    }

    &.for-what-fits {
      max-width: initial;
      width: auto;
      min-height: auto;
      button {
        padding: 0;
      }
    }
  }

  @media (min-width: ${({ theme }) => theme.viewports.mdx}) {
    min-height: 138px;
  }

  @media only screen and (min-width: ${({ theme }) => theme.viewports.sm}) and (max-device-width: ${({ theme }) =>
      theme.viewports.mdx}) and (orientation: portrait) {
    margin-bottom: 20px;
    //min-height: 108px;
  }

  @media (max-height: 450px) {
    width: 100%;
    min-height: auto;
  }

  &.svg-image {
    width: auto;
    min-height: auto;

    ${({ theme }) => theme.xs`
      width: 25%; 
      justify-content: center;
      margin-bottom: 2rem;
  `};
  }

  &.whatComesNext {
    ${({ theme }) => theme.xs`
      width: 25%; 
  `};
  }

  &.textOnly {
    ${({ theme }) => theme.xs`
      width: 25%; 
  `};
  }
`;

const CardHoldersNumberStyled = styled.div`
  color: ${(props) => props.theme.colors.darkFontColor};
  font-weight: bold;
  text-align: left;
  position: absolute;
  left: 10px;
  border: 1px solid #ccc;
  width: 25px;
  height: 25px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background: #f4f4f4;
  display: none;

  @media (min-width: ${({ theme }) => theme.viewports.sm}) {
    background: none;
    position: relative;
    display: flex;
    justify-content: center;
    margin-top: 10px;
    border: none;
    width: auto;
    height: auto;
    left: auto;
  }

  @media only screen and (min-width: ${({ theme }) => theme.viewports.sm}) and (max-device-width: ${({ theme }) =>
      theme.viewports.mdx}) and (orientation: portrait) {
    display: none;
  }

  @media only screen and (min-width: ${({ theme }) => theme.viewports.sm}) and (max-device-width: ${({ theme }) =>
      theme.viewports.mdx}) and (orientation: portrait) {
    display: flex;
  }

  @media (max-height: 450px) {
    display: none;
  }
`;

export const CardsList = ({
  items,
  selectedResponse,
  onResponse,
  isAbstract,
  isImage,
  isImageBorder,
  className,
  testId,
  onDblClick,
  config,
  isBlockModalVisible,
  isHelpVisible,
  isSidePanelOpen,
  iconsModule,
  listClassName,
  ...props
}) => {
  const hasShuffledResponses = testId === 'gcat';
  const handleResponse = React.useCallback(
    (id) => {
      onResponse(id);
    },
    [onResponse],
  );
  const direction = React.useContext(LangDirectionContext);
  const handleKeyboardEvent = (event) => {
    const key = event.which;
    
    if (isBlockModalVisible || isHelpVisible || isSidePanelOpen) return;
    
    if (key >= 49 && key <= 48 + items.length) {
      if (hasShuffledResponses) {
        handleResponse(items[key - 49].RIdx);
      }
      else {
        handleResponse(key - 48);
      }
    }

    if (key >= 97 && key <= 96 + items.length) {
      if (hasShuffledResponses) {
        handleResponse(items[key - 97].RIdx);
      }
      else {
        handleResponse(key - 96);
      }
    }
  };

  React.useEffect(() => {
    window.addEventListener('keydown', handleKeyboardEvent);

    return () => {
      window.removeEventListener('keydown', handleKeyboardEvent);
    };
  });
  
  return (
    <CardsListStyled {...props}>
      <CardsListWrapperStyled className={listClassName}>
        {items.map((item, i) => {
          return (
            <CardHoldersStyled direction={direction} key={item.RId} className={className}>
              <Card
                isAbstract={isAbstract}
                isImage={isImage}
                isImageBorder={isImageBorder}
                content={item.value} // TODO: Text or Images
                onClick={(e) => {
                  // to prevent situation when false "click" event triggered by enter keypress; default strange browser behaviour
                  // event.detail equals 0 when there was no actual click on the button
                  if (e.detail === 0) return;
                  handleResponse(item.RIdx);
                }}
                type={config.type}
                selected={hasShuffledResponses ? selectedResponse === i + 1 : selectedResponse === item.RIdx}
                // selected={selectedResponse === item.RIdx}
                testId={testId}
                iconsModule={iconsModule}
              />
              <CardHoldersNumberStyled className="numbers">{i + 1}</CardHoldersNumberStyled>
            </CardHoldersStyled>
          );
        })}
      </CardsListWrapperStyled>
    </CardsListStyled>
  );
};