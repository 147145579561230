export const textOnlyTests = [
  '18FQ',
  '15FQ',
  '15FQ+', 
  '15FQ+AZ3',
  '15FQ+C', 
  '15FQ+J', 
  '15FQ+VALUES', 
  '15FQ+VALUESC',
  '15FQ+VN4',
  '5PF',
  'BIGFIVETR',
  'EWQ', 
  'HSIP2009',
  'IPP',
  'JTI', 
  'LSI', 
  'OIP',
  'OIP+', 
  'OIP+AZ1',
  'OIP+ES1',
  'OIP+SA1',
  'OPP', 
  'OPPJ',
  'PVQ', 
  'PVQTH1',
  'SPI', 
  'SPIT',
  'SPT', 
  'TST',
  'ADAPTGN', 
  'ADAPTGV', 
  'GRT1V', 
  'GRT2V', 
  'IRT1NCH',
  'IRT1VCH',
  'IRT2NCH',
  'IRT2VCH',
  'IRT2N', 
  'IRT2V', 
  'IRT3N', 
  'IRT3V', 
  'IRT4V', 
  'IRT4N', 
  'APE1',
  'BRIGHT',
  'CCI', 
  'CTBIS', 
  'CEQ',
  'CM',
  'VMI', 
  'VMIPL3',
  'SRTI2',
  'GRT2MV',
  'GRT2MN',
  'ART',
  'WAI', 
  '360D',
  'GRTiV', 
  'GRTiN', 
  'lsjt2020liteg',
  'RTQ',
  'PQ10', 
  'CCAC', 
  'OPIC', 
  'GPS',
  '15FQ+VCH1',
  'TD12',
  'TD12CORE',
  'NOAHSCALES',
  'BIGFIVE',
  'IRT1CHV',
];

export const desktopOnlyTest = [
  'CRTBINTH', 
  'CRTBIN', 
  'CRTBIV', 
  'CRTBi', 
  'CRTB2', 
  'CRTB2V', 
  'CRTB2N', 
  'CRTB1N', 
  'CRTB3N', 
  'CTB2V', 
  'CTB2N', 
  'CTB2C', 
  'CTB2S', 
  'CCSI2008', 
  'CCSI2010', 
  'HSII', 
  'HSIC', 
  'HSIS', 
  'HSIP2009', 
  'IPTS', 
  'IPTC', 
  'IPTI', 
  'IPTN', 
  'ARTi', 
  'MRTi', 
  'SRTi', 
  'VACi', 
  'MRT2', 
  'SRT2', 
  'VAC', 
  'CMQ', 
  'SJTA', 
  'SJTB', 
  'SJM1', 
  'GCAT', 
  'LSJT1', 
  'lsjt2020liteg',
  'MSJT',
  'ARTi2',
  'ART',
  'ARTS',
  'MEX',
];

export const phoneLandscapeTests = [
  'ADAPTGA',  
  'ADAPTGASA',  
  'ADAPTGRTA', 
  'ADAPTGRTN', 
  'ADAPTGRTV', 
  'GRT2N', 
  'GRT2A', 
  'GRT1A', 
  'GRT1N',
  'IRT2A', 
  'IRT3A', 
  'IRT4A', 
  'GRT2MA', 
  'GRTiA', 
  'IRT1ACH', 
  'IRT2ACH', 
  'GRT3A',
];

export const LoginTypes = {
  login: 'login',
  login360: 'login360',
  auto360: 'auto360',
};

export const sameInstructionQuestionnaires = ['PaW', 'PVQ'];