import React from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { fetchQuestionnaireConfig } from "../../store/actions/testDetailsActions";

// FIXME: the functionality is copy-pasted from -> https://git.spsoft.com/cubasan/psytech-content-tester

const HolderStyled = styled.div`
  display: flex;
`;

export const IconStyled = styled.span`
  font-size: 120px;
  line-height: 1.2 !important;
  &.icon-question {
    color: #1baae1;
  }
`;

const CardStyled = styled.button`
  display: flex;
  background: transparent;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  flex-direction: column;
  height: 114px;
  box-shadow: none;
  padding: 9px;
  line-height: 1;
  font-size: 18px;
  letter-spacing: -0.32px;
  flex-grow: 1;
  color: black;
  text-align: center;
  position: relative;
  //width: 140px;
  //max-width: 140px;
  outline: none;
  touch-action: manipulation;
  margin-right: 20px;
  border: none;

  .svg-image & {
    width: auto;
  }

  @media (min-width: ${({ theme }) => theme.sm}) {
    //padding: 9px 8px;

    padding: 13px 7px; //TODO check every test for new paddings
    font-size: 18px;
  }

  span {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      width: auto;
      height: 100%;
      fill: black;
      position: absolute;
      width: 100%;
      top: 0;
      left: 0;
    }
  }
`;

export const GlyphsContainerStyled = styled.div`
  max-width: 800px;
  display: flex;
  margin-left: -12px;
  margin-right: -12px;
`;

export const GlyphStyled = styled.div`
  display: flex;
  flex-wrap: wrap;
  color: black;
  //border: 1px solid #d9d9d9;
  //border: 1px solid #000;
  border-radius: 4px;
  //padding: 13px 7px;
  margin: 0 0.85vw;
  &.question {
    border-color: #1baae1;
  }
  //&:before,
  //&:after {
  //  content: " ";
  //  display: inline-block;
  //  width: 9px;
  //  height: 0;
  //  background: none;
  //}

  span {
    svg {
      width: auto;
      height: 100%;
      width: 100%;
    }
  }
`;

const IconReStyled = styled(IconStyled)`
  line-height: 1.2 !important;
`;

const GlyphReStyled = styled(GlyphStyled)`
  padding: 0;
  margin: 0 10px;
`;

const defaultLang = "en";

export const Graphics = () => {
  const { testId } = useParams();
  const dispatch = useDispatch();
  const isFetchingTestDetails = useSelector(
    (state) => state.testDetails.isFetching
  );
  const testDetails = useSelector((state) => state.testDetails);

  React.useEffect(() => {
    dispatch(fetchQuestionnaireConfig(defaultLang, testId));
  }, [testId]);

  if (isFetchingTestDetails) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <Helmet>
        <link
          rel="stylesheet"
          type="text/css"
          href={`${process.env.PUBLIC_URL}/css/${testId}.css`}
        />
      </Helmet>
      <div>
        {testDetails.items.flat().map((item) => (
          <div key={item.id}>
            {item.qid && <h2>{item.qid}</h2>}
            {item.id && <h2>{item.id}</h2>}
            {item.template.values.mainitem && (
              <h3>{item.template.values.mainitem.default}</h3>
            )}
            {item.template.view === "XIsToY" && (
              <HolderStyled>
                <CardStyled>
                  {item.template.values.xtoy1}
                  <IconStyled
                    className={`icon icon-${item.template.values.xtoy1}`}
                  >
                    <i className="icon-path path1" />
                    <i className="icon-path path2" />
                    <i className="icon-path path3" />
                  </IconStyled>
                </CardStyled>
                <CardStyled>
                  {item.template.values.xtoy2}
                  <IconStyled
                    className={`icon icon-${item.template.values.xtoy2}`}
                  >
                    <i className="icon-path path1" />
                    <i className="icon-path path2" />
                    <i className="icon-path path3" />
                  </IconStyled>
                </CardStyled>
                <CardStyled>
                  {item.template.values.xtoy3}
                  <IconStyled
                    className={`icon icon-${item.template.values.xtoy3}`}
                  >
                    <i className="icon-path path1" />
                    <i className="icon-path path2" />
                    <i className="icon-path path3" />
                  </IconStyled>
                </CardStyled>
                <CardStyled>
                  {item.template.values.xtoy4}
                  <IconStyled
                    className={`icon icon-${item.template.values.xtoy4}`}
                  >
                    <i className="icon-path path1" />
                    <i className="icon-path path2" />
                    <i className="icon-path path3" />
                  </IconStyled>
                </CardStyled>
              </HolderStyled>
            )}
            {item.template.view === "WhatComesNext" && (
              <HolderStyled>
                <CardStyled>
                  {item.template.values.wcn1}
                  <IconStyled
                    className={`icon icon-${item.template.values.wcn1}`}
                  >
                    <i className="icon-path path1" />
                    <i className="icon-path path2" />
                    <i className="icon-path path3" />
                  </IconStyled>
                </CardStyled>
                <CardStyled>
                  {item.template.values.wcn2}
                  <IconStyled
                    className={`icon icon-${item.template.values.wcn2}`}
                  >
                    <i className="icon-path path1" />
                    <i className="icon-path path2" />
                    <i className="icon-path path3" />
                  </IconStyled>
                </CardStyled>
                <CardStyled>
                  {item.template.values.wcn3}
                  <IconStyled
                    className={`icon icon-${item.template.values.wcn3}`}
                  >
                    <i className="icon-path path1" />
                    <i className="icon-path path2" />
                    <i className="icon-path path3" />
                  </IconStyled>
                </CardStyled>
                <CardStyled>
                  {item.template.values.wcn4}
                  <IconStyled
                    className={`icon icon-${item.template.values.wcn4}`}
                  >
                    <i className="icon-path path1" />
                    <i className="icon-path path2" />
                    <i className="icon-path path3" />
                  </IconStyled>
                </CardStyled>
                <CardStyled>
                  <IconStyled className="icon icon-question">
                    <i className="icon-path path1" />
                    <i className="icon-path path2" />
                    <i className="icon-path path3" />
                  </IconStyled>
                </CardStyled>
              </HolderStyled>
            )}
            {item.template.view === "WhatFits" && (
              <HolderStyled>
                <GlyphReStyled>
                  <IconReStyled
                    className={`for-what-fits icon icon-${item.template.values.item1}`}
                  >
                    <i className="icon-path path1" />
                    <i className="icon-path path2" />
                  </IconReStyled>
                </GlyphReStyled>
                <GlyphReStyled>
                  <IconReStyled
                    className={`for-what-fits icon icon-${item.template.values.item2}`}
                  >
                    <i className="icon-path path1" />
                    <i className="icon-path path2" />
                  </IconReStyled>
                </GlyphReStyled>
                <GlyphReStyled>
                  <IconReStyled
                    className={`for-what-fits icon icon-${item.template.values.item3}`}
                  >
                    <i className="icon-path path1" />
                    <i className="icon-path path2" />
                  </IconReStyled>
                </GlyphReStyled>
                <GlyphReStyled>
                  <IconReStyled
                    className={`for-what-fits icon icon-${item.template.values.item4}`}
                  >
                    <i className="icon-path path1" />
                    <i className="icon-path path2" />
                  </IconReStyled>
                </GlyphReStyled>
                <GlyphReStyled>
                  <IconReStyled
                    className={`for-what-fits icon icon-${item.template.values.item5}`}
                  >
                    <i className="icon-path path1" />
                    <i className="icon-path path2" />
                  </IconReStyled>
                </GlyphReStyled>
                <GlyphReStyled>
                  <IconReStyled
                    className={`for-what-fits icon icon-${item.template.values.item6}`}
                  >
                    <i className="icon-path path1" />
                    <i className="icon-path path2" />
                  </IconReStyled>
                </GlyphReStyled>
                <GlyphReStyled>
                  <IconReStyled
                    className={`for-what-fits icon icon-${item.template.values.item7}`}
                  >
                    <i className="icon-path path1" />
                    <i className="icon-path path2" />
                  </IconReStyled>
                </GlyphReStyled>
                <GlyphReStyled>
                  <IconReStyled
                    className={`for-what-fits icon icon-${item.template.values.item8}`}
                  >
                    <i className="icon-path path1" />
                    <i className="icon-path path2" />
                  </IconReStyled>
                </GlyphReStyled>
                <GlyphReStyled>
                  <IconReStyled
                    className={`for-what-fits icon icon-${item.template.values.item9}`}
                  >
                    <i className="icon-path path1" />
                    <i className="icon-path path2" />
                  </IconReStyled>
                </GlyphReStyled>
              </HolderStyled>
            )}
            {item.template.view === "HowFitsTogether" && (
              <HolderStyled>
                <CardStyled>
                  {item.template.values.mainshape}
                  <IconStyled
                    className={`icon icon-${item.template.values.mainshape}`}
                  >
                    <i className="icon-path path1" />
                    <i className="icon-path path2" />
                    <i className="icon-path path3" />
                    <i className="icon-path path4" />
                    <i className="icon-path path5" />
                    <i className="icon-path path6" />
                  </IconStyled>
                </CardStyled>
              </HolderStyled>
            )}
            <br />
            <br />
            <HolderStyled>
              {item.template.values.responses.map((response) => (
                <CardStyled key={response.value.default}>
                  {response.value.default}
                  <IconStyled className={`icon icon-${response.value.default}`}>
                    <i className="icon-path path1" />
                    <i className="icon-path path2" />
                    <i className="icon-path path3" />
                    <i className="icon-path path4" />
                    <i className="icon-path path5" />
                    <i className="icon-path path6" />
                  </IconStyled>
                </CardStyled>
              ))}
            </HolderStyled>
            <br />
            <br />
            <hr />
          </div>
        ))}
      </div>
    </div>
  );
};
