import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Button, ButtonTypes, ButtonVariants } from './Button';
import { ErrorImage } from './ErrorImage';
import { Page, Title } from './layout';
import { ButtonsHolder } from './layout/ButtonHolder';
import { ModalBox } from './layout/Page';
import { Spinner } from './Spinner';

const ModalBoxStyled = styled(ModalBox)`
  padding: 100px 58px 40px 58px;
  justify-content: space-between;
  min-height: 526px; // For IE

  @media (max-height: 450px) {
    min-height: auto;
    padding: 20px !important;
  }
`;

const ErrorPage = styled(Page)`
  justify-content: center;
`;

const MessageStyled = styled.div`
  margin-bottom: 20px;
  color: ${({ theme }) => theme.colors.font};
  text-align: center;
  font-size: 18px;
  line-height: 26px;
  letter-spacing: -0.5px;
  margin-top: 20px;
  max-width: 100%;
  flex-grow: 1;
`;

const ButtonStyled = styled(Button)`
  width: 100%;
`;

export const Error = ({ children, onNext, title, isPending }) => {
  const { t } = useTranslation();

  const buttonRefNext = React.createRef();

  React.useEffect(() => {
    setTimeout(() => {
      try {
        buttonRefNext.current.focus();
      } catch (e) {
        // console.log("Focus is not available");
      }
    }, 0);
  }, [buttonRefNext]);

  return (
    <ErrorPage withBackground>
      <ModalBoxStyled>
        <ErrorImage />
        <Title>{title || t('error')}</Title>
        <MessageStyled dangerouslySetInnerHTML={{ __html: children }} />
        <ButtonsHolder>
          <ButtonStyled
            ref={buttonRefNext}
            variant={ButtonVariants.PRIMARY}
            type={ButtonTypes.BUTTON}
            onClick={onNext}
            disabled={isPending}
          >
            {isPending ? <Spinner small /> : t('ok')}
          </ButtonStyled>
        </ButtonsHolder>
      </ModalBoxStyled>
    </ErrorPage>
  );
};
