import styled from "styled-components";
import { Page, Title } from "../../shared/layout";
import { ModalBox } from "../../shared/layout/Page";


export const ReviewModalPage = styled(Page)`
  justify-content: center;
`;

export const ModalBoxStyled = styled(ModalBox)`
  padding: 0 40px;
`;

export const MessageStyled = styled.h2`
  font-size: 18px;
  line-height: 26px;
  font-weight: bold;
  letter-spacing: -0.5px;
  color: ${({ theme }) => theme.colors.font};
  text-align: center;
  margin-bottom: 60px;
`;

export const ExtraMessageStyled = styled.h2`
  font-size: 18px;
  line-height: 26px;
  font-weight: bold;
  letter-spacing: -0.5px;
  color: ${({ theme }) => theme.colors.font};
  text-align: center;
  margin-top: -40px;
  margin-bottom: 40px;
`;

export const TitleStyled = styled(Title)`
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
  text-transform: uppercase;
  margin-bottom: 30px;
  margin-top: 50px;
  max-width: 100%;

  @media (max-height: 450px) {
    margin: 0;
  }
`;
