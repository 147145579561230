import clsx from 'clsx';
import React from 'react';
import styled from 'styled-components';
import { CardsList } from '../shared/CardsList';
import { FeedbackStyled, FullHeight, QuestionTextStyled } from './shared/components';

const NewClipStyled = styled.div`
  background: white;
  box-shadow: 10px 13px 10px #00000029;
  border: 1px solid #295368;
  padding: 16px 24px;
  color: ${({ theme }) => theme.colors.font};
  position: relative;
  margin-bottom: 20px;

  @media (max-height: ${({ theme }) => theme.viewports.presumablyDesktopHeight}) {
    padding: 8px 16px;
    margin-bottom: 10px;
  }

  &:before,
  &:after {
    z-index: 1;
    content: '';
    height: 5px;
    position: absolute;
    left: 0;
    right: 0;
    bottom: -6px;
    background: #295368;
  }

  &:before {
    z-index: 2;
    background: white;
    bottom: -5px;
    box-shadow: none;
  }
`;
const QuestionTextReStyled = styled(QuestionTextStyled)`
  margin-bottom: 0;

  @media (min-width: ${({ theme }) => theme.viewports.sm}) {
    font-size: 24px;
    line-height: 22px;
  }
  @media (min-width: ${({ theme }) => theme.viewports.lg}) {
    font-size: 24px;
    line-height: 1.67;
  }
  @media (max-height: ${({ theme }) => theme.viewports.presumablyDesktopHeight}) {
    padding-top: 20px;
    padding-bottom: 12px;
    font-size: 18px;
  }
  @media only screen and (min-width: ${({ theme }) => theme.viewports.sm}) and (max-device-width: ${({ theme }) =>
      theme.viewports.mdx}) and (orientation: portrait) {
    padding-top: 0;
    padding-bottom: 0;
    font-size: 20px;
  }
`;
const TitledStyled = styled.div`
  padding: 10px 0;
  border-bottom: 2px solid #295368;
  border-top: 2px solid #295368;
  letter-spacing: 0.45px;
  font-weight: bold;
  font-size: 18px;
  line-height: 27px;

  @media (max-height: ${({ theme }) => theme.viewports.presumablyDesktopHeight}) {
    padding: 5px 0;
  }
`;

const Feedback = styled(FeedbackStyled)`
  @media (max-height: ${({ theme }) => theme.viewports.presumablyDesktopHeight}) {
    padding-bottom: 10px;
  }
`;

const ContentStyled = styled.div`
  padding: 10px 0;
  font-weight: bold;
  font-size: 18px;
  color: ${({ theme }) => theme.colors.font};
  line-height: 20px;
  @media (max-height: ${({ theme }) => theme.viewports.presumablyDesktopHeight}) {
    font-size: 16px;
    line-height: 20px;
  }

  @media only screen and (min-width: ${({ theme }) => theme.viewports.sm}) and (max-device-width: ${({ theme }) =>
      theme.viewports.mdx}) and (orientation: portrait) {
    font-size: 16px;
    line-height: 22px;
  }
`;

const StyledFullHeight = styled(FullHeight)`
  @media (min-width: ${({ theme }) => theme.viewports.sm}) {
    padding-top: 70px;
  }
`;

export const NewsClipItem = ({
  values: { mainitem, responses, newsclip, feedback },
  selectedResponse,
  onResponse,
  config,
  isBlockModalVisible,
  isHelpVisible,
  isSidePanelOpen,
}) => {
  return (
    <>
      <StyledFullHeight>
        <NewClipStyled>
          <TitledStyled
            dangerouslySetInnerHTML={{
              __html: newsclip.title,
            }}
          />
          <ContentStyled
            dangerouslySetInnerHTML={{
              __html: newsclip.content,
            }}
          />
        </NewClipStyled>
        <QuestionTextReStyled
          dangerouslySetInnerHTML={{
            __html: mainitem,
          }}
        />
        {!!feedback && (
          <Feedback
            dangerouslySetInnerHTML={{
              __html: feedback,
            }}
          />
        )}
      </StyledFullHeight>
      <CardsList
        isAbstract={config.isAbstract}
        className={clsx({ 'svg-image': config.isAbstract })}
        items={responses}
        selectedResponse={selectedResponse}
        onResponse={onResponse}
        config={config}
        isBlockModalVisible={isBlockModalVisible}
        isHelpVisible={isHelpVisible}
        isSidePanelOpen={isSidePanelOpen}
      />
    </>
  );
};
