import { Form } from "formik";
import styled, { css } from "styled-components";
import { Button } from "../../shared/Button";
import { Footer } from "../../shared/Footer";
import { Page, Title } from "../../shared/layout";
import { ButtonsHolder } from "../../shared/layout/ButtonHolder";
import { Divider } from "../../templates/shared/components";

export const StyledPage = styled(Page)`
  align-items: center;
  padding: 0;
  overflow: hidden;
  left: 0;

  @media (min-width: ${({ theme }) => theme.viewports.sm}) {
    padding: 0;
    position: fixed;
    align-items: center;
    justify-content: center;
  }

  .bio-wrapper {
    overflow-y: auto;
    overflow-x: hidden;
    width: 100%;
    z-index: 5;
    position: relative;
    margin-bottom: 80px;
    padding: 20px 5%;
    height: calc(100vh - 80px);

    @media (min-width: ${({ theme }) => theme.viewports.sm}) {
      height: auto;
    }
    @media (max-height: 414px) {
      margin-bottom: 40px;
    }
  }
`;

export const BioStyled = styled(Form)`
  width: auto;
  padding-bottom: 20px;
  max-width: 490px;
  margin: auto;
  position: relative;

  @media (min-width: ${({ theme }) => theme.viewports.sm}) {
    height: auto;
    flex-direction: row;
    justify-content: space-between;
    display: flex;
    width: 100%;

    ${(props) =>
    props.fullSize &&
    css`
       width: 44rem;
     `}

    max-width: 1280px;
    border-radius: 10px;
    padding: 10px 20px;

    &:before {
      content: " ";
      position: absolute;
      width: 100%;
      height: 100%;
      backdrop-filter: blur(15px);
      top: 0;
      left: 0;
      z-index: -1;
      border: 2px solid white;
      border-radius: 10px;
    }
  }

  @media (min-width: ${({ theme }) => theme.viewports.md}) {
    padding: 15px 30px;
  }

  .flex-box {
    width: 100%;
    max-width: 100%;

    @media (min-width: ${({ theme }) => theme.viewports.sm}) {
      display: inline-flex;
      min-width: 310px;
    }

    @media (min-width: ${({ theme }) => theme.viewports.md}) {
      width: auto;
    }
  }
`;

export const HalfWrapperStyled = styled.div`
  width: 100%;
  max-width: 490px;
  margin: 0 auto;

  @media (min-width: ${({ theme }) => theme.viewports.sm}) {
    max-width: none;
    width: calc(50% - 10px);
    margin: initial;
    flex-direction: column;
    display: flex;
    &:first-of-type {
      padding-top: 15px;
    }
  }

  @media (min-width: ${({ theme }) => theme.viewports.md}) {
    width: calc(50% - 15px);
  }

  &:last-of-type {
    padding-top: 15px;
  }
`;

export const DividerStyled = styled(Divider)`
  display: none;

  @media (min-width: ${({ theme }) => theme.viewports.sm}) {
    display: block;
    min-width: 2px;
    margin-left: 20px;
    margin-right: 20px;
    background-color: white;
  }

  @media (min-width: ${({ theme }) => theme.viewports.md}) {
    margin-left: 30px;
    margin-right: 30px;
  }
`;
export const TitleStyled = styled(Title)`
  width: 100%;
  color: ${({ theme }) => theme.colors.white};
  left: 50%;
  right: auto;
  z-index: 5;
  top: 0;
  margin: auto;
  height: ${({ theme }) => theme.footer.height};
  display: flex;
  align-items: center;
  padding: 20px 0 50px 0;
  max-width: 490px;

  @media (min-width: ${({ theme }) => theme.viewports.sm}) {
    color: ${({ theme }) => theme.colors.white};
    max-width: 1280px;
    position: relative;
    left: 0;
    font-size: 28px;
    padding: 0;
    transform: none;
    margin-top: 0;
    background-color: transparent;
    display: block;
    margin-left: auto;
    margin-right: auto;
    text-align: inherit;

    ${(props) =>
    props.fullSize &&
    css`
       left: 50%;
       transform: translateX(-25%);
     `}
  }

  @media (min-width: ${({ theme }) => theme.viewports.md}) {
   ${(props) =>
    props.fullSize &&
    css`
       transform: translateX(-30%);
     `}
`;

export const FooterStyled = styled(Footer)`
  min-height: 80px;

  z-index: 5;
  display: flex;
  position: fixed;
  bottom: 0;
  width: 100%;
  @media (min-width: ${({ theme }) => theme.viewports.xs}) {
    background-color: ${({ theme }) => theme.colors.font};
  }
  @media (max-height: 414px) {
    min-height: 54px;
  }
  .wrapper {
    min-height: 80px;
    background: transparent;
    flex-direction: row;
    @media (max-height: 414px) {
      min-height: 54px;
    }
  }
`;

export const ButtonStyled = styled(Button)`
  @media (min-width: ${({ theme }) => theme.viewports.xs}) {
    width: auto;
  }
`;

export const ButtonsHolderStyled = styled(ButtonsHolder)`
  width: 100%;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
  padding: 0 5%;
  @media (min-width: ${({ theme }) => theme.viewports.sm}) {
    padding: 0;
  }
`;
