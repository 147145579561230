import styled from "styled-components";
import { Button } from "../../shared/Button";
import { Footer } from "../../shared/Footer";
import { Page, Title } from "../../shared/layout";
import { ButtonsHolder } from "../../shared/layout/ButtonHolder";

export const StyledPage = styled(Page)`
  justify-content: center;
  margin: auto;
  padding-bottom: 20px;
  position: relative;
  background: none;
`;

export const InfoFieldStyled = styled.div`
  font-size: 12px;
  line-height: 18px;
  color: ${({ theme }) => theme.colors.dark};
  display: flex;
  margin-top: 16px;
  align-items: center;

  a {
    color: ${({ theme }) => theme.colors.dark};
    text-decoration: none;
    font-weight: bold;
  }

  &:after {
    display: inline-flex;
    min-width: 10px;
    content: "";
    order: -1;
  }

  &:before {
    height: 28px;
    min-width: 28px;
    content: "!";
    color: ${({ theme }) => theme.colors.light};
    border: 3px solid ${({ theme }) => theme.colors.light};
    font-size: 20px;
    display: inline-flex;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    font-weight: 700;
    order: -2;

    @media (min-width: ${({ theme }) => theme.viewports.sm}) {
      height: 34px;
      min-width: 34px;
      font-size: 24px;
    }
  }
`;

export const TitleStyled = styled(Title)`
  width: 100%;
  color: ${({ theme }) => theme.colors.white};
  left: 50%;
  right: auto;
  z-index: 5;
  top: 0;
  margin: auto;
  height: ${({ theme }) => theme.footer.height};
  display: flex;
  align-items: center;
  padding: 20px 0 50px 0;
  max-width: 490px;

  @media (min-width: ${({ theme }) => theme.viewports.sm}) {
    color: ${({ theme }) => theme.colors.white};
    max-width: 1280px;
    position: relative;
    left: 0;
    font-size: 28px;
    padding: 0;
    transform: none;
    margin-top: 0;
    background-color: transparent;
    display: block;
    margin-left: auto;
    margin-right: auto;
    text-align: inherit;
  }

    @media (max-height: 450px) {
      padding: 0;
    }

`;

export const FooterStyled = styled(Footer)`
  min-height: 80px;

  z-index: 5;
  display: flex;
  position: fixed;
  bottom: 0;
  width: 100%;
  @media (min-width: ${({ theme }) => theme.viewports.xs}) {
    background-color: ${({ theme }) => theme.colors.font};
  }
  @media (max-height: 414px) {
    min-height: 54px;
  }
  .wrapper {
    min-height: 80px;
    background: transparent;
    flex-direction: row;
    @media (max-height: 414px) {
      min-height: 54px;
    }
  }
`;

export const ButtonStyled = styled(Button)`
  @media (min-width: ${({ theme }) => theme.viewports.xs}) {
    width: auto;
  }
`;

export const ButtonsHolderStyled = styled(ButtonsHolder)`
  width: 100%;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
  padding: 0 5%;
  @media (min-width: ${({ theme }) => theme.viewports.sm}) {
    padding: 0;
  }
`;

export const TextStyled = styled.span`
   {
    height: 300px;
    display: flex;
    align-items: center;
    line-height: 1.5;
    color: #375563;
    text-align: center;
    font-size: 1.4rem;

    @media (max-height: 450px) {
      height: 130px;
    }

  }
`;