import React from 'react';
import styled, { css } from 'styled-components';

const IMAGE_MAX_WIDTH = 290;

const CardStyled = styled.button`
  display: ${(props) => (props.separateSvgIcon ? 'flex' : '-webkit-box')};
  background: transparent;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  flex-direction: column;
  height: fit-content;
  box-shadow: none;
  transition: box-shadow 0.2s;
  padding: 9px;
  line-height: 1;
  font-size: 18px;
  letter-spacing: -0.32px;
  flex-grow: 1;
  border: 1px solid #C0C0C0;
  border-radius: 6px;
  color: ${(props) => props.theme.colors.darkFontColor};
  text-align: center;
  position: relative;
  width: 100%;
  outline: none;
  touch-action: manipulation;

  @media (max-width: ${({ theme }) => theme.viewports.xxs}) {
    font-size: 16px;
  }
  
  @media (max-width: ${({ theme }) => theme.viewports.xs}) and (orientation: portrait) {
    .whatComesNext & {
      width: auto;
      height: auto;
    }
  }
    
  @media (min-width: ${({ theme }) => theme.viewports.sm}) {

    padding: 8px 0; //TODO check every test for new paddings
    font-size: 18px;

    &:hover:not(:active) {
      cursor: pointer;
      box-shadow: 0 7px 10px 0 rgba(0, 0, 0, 0.2);
      border-color: ${({ theme }) => theme.colors.black};
    }
  }
  
  ${(props) =>
    props.selected &&
    css`
      color: ${({ theme }) => theme.colors.darkFontColor}!important;
      border-color: #1baae1 !important;
      box-shadow: 0 0 0 4px ${({ theme }) => theme.colors.light};
      transition: none;

      @media (min-width: ${({ theme }) => theme.viewports.sm}) {
        box-shadow: 0 0 0 4px ${({ theme }) => theme.colors.light};

        &:hover {
          box-shadow: 0 0 0 4px #1baae1 !important;
        }
      }
    `}

      img {
          width: ${IMAGE_MAX_WIDTH}px;
      }
`;

export const ImageCard = ({ content, onClick, selected }) => {
    return (<CardStyled
      role="button"
      tabIndex="0"
      onClick={onClick}
      selected={selected}
  >
      <img src={content} alt="Checking Test Content"/>
  </CardStyled>)
};